@use "../abstracts" as *;

@import "~@splidejs/splide/dist/css/splide.min.css";

// Splide Overwrite
.splide {
    &__arrow {
        background: transparent;

        &--next {
            right: -1em;

            @include for-phone-only {
                right: 0em;
            }
        }

        &--prev {
            left: -1em;

            @include for-phone-only {
                left: 0em;
            }
        }

        svg {
            fill: #a7a7a7;
        }

        @include for-desktop-up {
            &--next {
                right: -2em;
            }

            &--prev {
                left: -2em;
            }
        }
    }

    &__pagination {
        bottom: -1.5em;

        &__page {
            width: 18px;
            height: 6px;
            border-radius: 100px;
        }

        &__page.is-active {
            background: color(primary, 300);
        }
    }
}
