@use "./functions" as *;

// Medias
@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-phone-and-tablet {
    @media (max-width: 990px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin for-tablet-portrait-only {
    @media (min-width: 600px) and (max-width: 899px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin for-tablet-landscape-only {
    @media (min-width: 900px) and (max-width: 1199px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-desktop-only {
    @media (min-width: 1200px) and (max-width: 1799px) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

@mixin flex($display, $direction, $align, $justify) {
    display: $display;
    flex-direction: $direction;
    align-items: $align;
    justify-content: $justify;
}

@mixin grid($display, $columns, $rows, $gap) {
    display: $display;
    grid-template-columns: $columns;
    grid-template-rows: $rows;
    gap: $gap;
}

@mixin button(
    $background,
    $fontSize,
    $color,
    $width,
    $height,
    $colorHover: $color
) {
    color: $color;
    background-color: color($background, 400);
    font-size: $fontSize;
    width: $width;
    min-height: $height;
    box-shadow: 0px 4px 4px color($background, 400, 0.1);

    &:focus {
        box-shadow: 0 0 0 0.2rem color($background, 400, 0.65);
    }
}

@mixin placeholder($color, $padding, $fontSize) {
    &::placeholder {
        /* WebKit browsers */
        color: $color;
        padding: $padding;
        font-size: $fontSize;
    }

    &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: $color;
        padding: $padding;
        font-size: $fontSize;
    }

    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $color;
        padding: $padding;
        font-size: $fontSize;
    }

    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $color;
        padding: $padding;
        font-size: $fontSize;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: $color;
        padding: $padding;
        font-size: $fontSize;
    }
}
