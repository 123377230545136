@use "../abstracts" as *;
@use "../components/card";
@use "../components/splide";
@import "../../variables";

.banner {
    position: relative;
    overflow: hidden;

    &-splide {
        li {
            @include flex(flex, column, center, center);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include for-phone-only {
                    width: 425px;
                }
            }
        }

        &__content {
            @include flex(flex, column, flex-start, center);
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            width: 100%;
            height: auto;
            padding: 1rem 0.5rem;
            transform: translate(-50%, -50%);

            @include for-desktop-up {
                max-width: fit-content;
                height: auto;
                background-color: transparent;
                left: 14em;
                transform: translateY(-50%);
            }

            h2 {
                color: white;
                font-size: size(title, 500);
                font-weight: 900;

                @include for-phone-and-tablet {
                    font-size: size(title, 100);
                }

                strong {
                    font-weight: 900;
                    color: color(secondary, 200);
                }
            }

            p {
                color: white;

                @include for-phone-only {
                    font-size: size(text, 300);
                }

                @include for-tablet-portrait-up {
                    font-size: size(text, 400);
                }

                &:last-of-type {
                    margin-bottom: 1rem;
                }
            }
        }

        &__button {
            min-width: 134px;
            height: 42px;

            @include for-phone-only {
                max-width: 92px;
                font-size: size(text, 200);
            }
        }
    }

    .splide {
        &__arrow {
            &--next {
                right: 2rem;
            }

            &--prev {
                left: 2rem;
            }

            svg {
                width: 29px;
                height: 46px;
                fill: color(secondary, 300);
            }
        }

        &__pagination {
            bottom: 1.5rem;

            &__page {
                border: 1px solid color(secondary, 300);

                &:not(.is-active) {
                    background-color: color(secondary, 300);
                    border: 1px solid color(primary, 300);
                }
            }
        }
    }

    @include for-tablet-portrait-only {
        .splide__arrow {
            top: 60%;
        }
    }
}

.fast-access {
    &__card {
        position: relative;
    }

    &__content {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) translateY(10%);

        h3 {
            color: color(secondary, 300);
            font-size: size(title, 300);
            font-weight: 700;
        }

        p {
            color: color(white, 100);
            font-size: size(text, 500);
        }
    }
}

.products {
    background: color(grayScale, 500);

    &__title {
        font-family: $font-main;
        margin-bottom: 4rem;
    }

    &__card {
        margin-bottom: 4rem;
    }

    .product {
        @include flex(flex, column, center, center);
        @extend %transitionDefault;
        background: #D8D8D8;
        border-radius: 12px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);

        &__content {
            &-image {
                border-radius: 12px;
            }

            &-name {
                @extend %transitionDefault;
                color: color(grayScale, 900);
                font-size: size(title, 100);
                font-weight: bold;
            }

            &-summary p {
                color: #000;
                font-size: size(text, 400);
            }

            &-button {
                background: color(grayScale, 900);
                color: color(white, 100);
                ;
                padding: 8px 38px;
                border-radius: 4px;
            }
        }

        @include for-desktop-up {
            &:hover {
                transform: scale(1.18);

                .product__content-name {
                    color: rgba(51, 51, 53, 0.74);
                }

                .product__content-button {
                    background: rgba(51, 51, 53, 0.74);
                    ;
                }
            }
        }
    }
}


.about {
    background: color(grayScale, 500);

    &-title {
        color: color(grayScale, 700);
    }

    &-description {
        color: color(grayScale, 600);

        h3 {
            font-size: size(title, 100);
            color: color(blackScale, 500);
        }
    }
}